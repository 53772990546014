<template>
  <div class="user">
    <div class="top">
      <el-button class="create" type="primary" @click="create()">新建</el-button>
    </div>
    <div class="content">
      <el-table v-loading="loading" :data="info.data" stripe>
        <el-table-column prop="status" label="状态" width="80">
          <template #default="scope">
            <el-switch :value="scope.row.status === 1" @change="statusChange(scope.row.uid, scope.row.status)"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="identifier" label="用户名"> </el-table-column>
        <el-table-column prop="roleName" label="角色"> </el-table-column>
        <el-table-column prop="lastLoginTime" label="最后登录时间"> </el-table-column>
        <el-table-column width="280" prop="uid" label="操作">
          <template #default="scope">
            <div style="width: 80%; display: flex; justify-content: space-between">
              <el-button type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
              <el-popover placement="bottom-end" v-model:visible="popover[scope.row.uid]" width="400" trigger="click" @hide="resetCancel(scope.row.uid)">
                <el-form :model="password">
                  <el-form-item label="重置密码">
                    <el-input v-model="password.credential" autocomplete="off">
                      <template #append>
                        <el-button type="primary" size="small" @click="generate">生成密码</el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <div style="width: 100%; display: flex; justify-content: space-around">
                      <el-button style="width: 130px" @click="resetCancel(scope.row.uid)">取消</el-button>
                      <el-button style="width: 130px" type="primary" @click="resetConfirm(scope.row.uid)">确定</el-button>
                    </div>
                  </el-form-item>
                </el-form>
                <template #reference>
                  <el-button size="small">重置密码</el-button>
                </template>
              </el-popover>
              <el-button size="small" @click="del(scope.row.uid)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        hide-on-single-page
        :current-page="pageNo"
        :page-sizes="[10, 30, 50, 100, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="info.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-dialog
        v-if="createVisible"
        v-model="createVisible"
        width="30%"
        :close-on-click-modal="false"
        :title="actions === 'create' ? '新建用户' : '编辑用户'"
        @close="createCancel"
      >
        <!--  :visible.sync="createVisible" -->
        <el-form ref="form" status-icon :model="user" :rules="formRules">
          <el-form-item label="姓名" prop="name">
            <el-input ref="name" v-model="user.name" name="name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="identifier">
            <el-input ref="identifier" v-model="user.identifier" name="identifier" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleId">
            <el-select ref="roleId" v-model="user.roleId" name="roleId" placeholder="请选择分配的角色">
              <el-option v-for="(item, index) in rolesMeta" :key="`roles-${index}`" :label="item.roleName" :value="item.roleId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="密码" prop="credential">
            <el-input ref="credential" v-model="user.credential" show-password name="credential" type="password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="width: 100%; display: flex; justify-content: space-around; margin-top: 25px">
              <el-button style="width: 130px" @click="createCancel">取消</el-button>
              <el-button style="width: 130px" type="primary" :loading="catchRequest" @click="createConfirm">确定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { validUsername, validPassword, validName, validRoleId, setUserInfo } from '@/utils/utils'

import http from '@/utils/http'
import G from '@/config/config'
import { apiGetRoles, AuthItem } from '@/api/role'
import { VuexStateKey } from '@/store/vuexEnum'
import store from '@/store'
import { UserInfo } from '@/vo/UserInfo'
import { PageList } from '@/vo/PageList'
import { ElForm, ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import _ from 'lodash'
import { defineComponent } from 'vue'
function randomPassword() {
  const length = 8
  const passwordArray = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '-_@!#$%^&*']
  let password = []
  let n = 0
  for (let i = 0; i < length; i++) {
    // If password length less than 9, all value random
    if (password.length < length - 4) {
      // Get random passwordArray index
      const arrayRandom = Math.floor(Math.random() * 4)
      // Get password array value
      const passwordItem = passwordArray[arrayRandom]
      // Get password array value random index
      // Get random real value
      const item = passwordItem[Math.floor(Math.random() * passwordItem.length)]
      password.push(item)
    } else {
      // If password large then 9, lastest 4 password will push in according to the random password index
      // Get the array values sequentially
      const newItem = passwordArray[n]
      const lastItem = newItem[Math.floor(Math.random() * newItem.length)]
      // Get array splice index
      const spliceIndex = Math.floor(Math.random() * password.length)
      password.splice(spliceIndex, 0, lastItem)
      n++
    }
  }
  return password.join('')
}

const DEFAULT_USER = {
  name: '',
  identifier: '',
  roleId: undefined,
  credential: '',
  platform: G.platform,
  lastLoginTime: '',
  uid: -1,
  roleName: '',
  status: -1,
} as UserInfo

export default defineComponent({
  name: 'User',
  data() {
    const validateName = (rule: unknown, value: string, callback: Function) => {
      if (!validName(value)) {
        callback(new Error('姓名格式不正确：最长50位'))
      } else {
        callback()
      }
    }
    const validateUsername = (rule: unknown, value: string, callback: Function) => {
      if (!validUsername(value)) {
        callback(new Error('用户名格式不正确：最长20位，支持“A-Z”，“a-z”，“0-9”'))
      } else {
        callback()
      }
    }
    const validateRoleId = (rule: unknown, value: number, callback: Function) => {
      if (!validRoleId(value)) {
        callback(new Error('角色不能为空，请选择一个角色'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule: unknown, value: string, callback: Function) => {
      if (!validPassword(value)) {
        callback(new Error('密码格式不正确：最长20位最少8位，支持“A-Z”、“a-z”、“0-9”、“-_@!#$%^&*”'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      createVisible: false,
      user: _.cloneDeep(DEFAULT_USER),
      password: {
        uid: -1,
        credential: '',
      },
      popover: {} as {
        [index: number]: boolean
      },
      formRules: {
        name: [{ required: true, trigger: 'blur', validator: validateName }],
        identifier: [{ required: true, trigger: 'blur', validator: validateUsername }],
        roleId: [{ required: true, trigger: 'change', validator: validateRoleId }],
        credential: [{ required: true, trigger: 'blur', validator: validatePassword }],
      },
      actions: 'create',
      catchRequest: false,
      pageNo: 1,
      pageSize: 10,
      info: {},
      rolesMeta: Array<AuthItem>(),
    }
  },
  computed: {
    userInfo() {
      return store.state[VuexStateKey.userInfo] as UserInfo
    },
    status(status: number) {
      return status === 1
    },
  },
  created() {
    this.init()
    apiGetRoles().then(resp => {
      this.rolesMeta = resp
    })
  },
  methods: {
    init() {
      this.loading = true
      return http<PageList<UserInfo>>('GET', `/correct/bs/userdomain/user/query/all`, {
        platform: G.platform,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      })
        .then(resp => {
          resp.data.forEach(item => {
            this.popover[item.uid] = false
          })
          this.info = resp
          return resp
        })
        .finally(() => {
          this.loading = false
        })
    },
    statusChange(uid: number, status: number) {
      http('POST', `/correct/bs/userdomain/user/update/status`, {
        platform: G.platform,
        status: status === 1 ? 2 : 1,
        uid: uid,
      }).then(() => {
        this.init().then(() => {
          this.createVisible = false
        })
      })
    },
    create() {
      this.createVisible = true
      this.actions = 'create'
    },
    createCancel() {
      this.user = _.cloneDeep(DEFAULT_USER)
      this.$nextTick(() => {
        this.createVisible = false
      })
    },
    createConfirm() {
      let eleForm = this.$refs.form as InstanceType<typeof ElForm>
      eleForm.validate(valid => {
        if (valid) {
          this.catchRequest = true
          if (this.actions === 'create') {
            http('POST', `/correct/bs/userdomain/user/create`, this.user)
              .then(() => {
                this.user = _.cloneDeep(DEFAULT_USER)
                this.createVisible = false
                ElMessage({
                  type: 'success',
                  message: '添加成功',
                })
              })
              .finally(() => {
                this.catchRequest = false
                this.user = _.cloneDeep(DEFAULT_USER)
              })
          } else {
            http('POST', `/correct/bs/userdomain/user/update`, this.user)
              .then(() => {
                ElMessage({
                  type: 'success',
                  message: '修改成功',
                })
                if (this.user.uid === this.userInfo.uid) {
                  setUserInfo(Object.assign({}, this.userInfo, this.user))
                }
                this.init().then(() => {
                  this.createVisible = false
                })
              })
              .finally(() => {
                this.catchRequest = false
              })
          }
        } else {
          return false
        }
      })
    },
    edit(data: UserInfo) {
      this.user = {
        ...data,
        roleId: data.roleId,
      }
      this.$nextTick(() => {
        this.actions = 'edit'
        this.createVisible = true
      })
    },
    generate() {
      this.password.credential = randomPassword()
    },
    resetCancel(uid: number) {
      this.password = {
        credential: '',
        uid: -1,
      }
      this.$nextTick(() => {
        this.popover[uid] = false
      })
    },
    resetConfirm(uid: number) {
      this.password.uid = uid
      if (!this.password.credential) {
        ElMessage.error('密码不能为空')
        return false
      } else if (!validPassword(this.password.credential)) {
        ElMessage.error('密码格式不正确')
        return false
      }

      http('POST', `/correct/bs/userdomain/user/update/credential`, this.password).then(() => {
        this.init().then(() => {
          this.popover[uid] = false
        })
      })
    },
    del(uid: number) {
      ElMessageBox.confirm('确认删除用户吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        http('POST', `/correct/bs/userdomain/user/delete?uid=${uid}`, {
          uid,
        }).then(() => {
          ElMessage.success('删除成功')
          this.init()
        })
      })
    },
    handleCurrentChange(pageNo: number) {
      this.pageNo = pageNo
      this.init()
    },
    handleSizeChange(pageSize: number) {
      this.pageSize = pageSize
      if (this.pageNo != 1) {
        this.pageNo = 1
      } else {
        this.$nextTick(() => {
          this.init()
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 45px;
  margin-top: 25px;
  box-sizing: border-box;
}
.content {
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
}
.el-pagination {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}
.el-button:focus {
  background-color: #fff;
  border-color: #dcdfe6;
  color: #606266;
}
</style>
