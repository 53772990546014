import G from '@/config/config'
import http from '@/utils/http'

export interface AuthItem {
  authId: number
  authName: string
  platform: number
  subAuth: AuthItem[]
}

export function apiGetRoles() {
  return http<AuthItem[]>('GET', `/correct/bs/userdomain/role/query`, {
    platform: G.platform,
  })
}
